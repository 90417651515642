import React, { useEffect, useState } from "react";
import { BiSearchAlt } from "react-icons/bi";

import "../../styles/Home.scss";
import HomeContact from "./HomeContact";
import Servicii from "./Servicii";
import Footer from "../../components/Footer";
import HomePopup from "../../components/HomePopup/HomePopup";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

function Home() {
  const gaEventTracker = useAnalyticsEventTracker("Contact");

  const [searchbarValue, setSearchbarValue] = useState("");
  const [buttonPopup, setButtonPopup] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setButtonPopup(true);
    }, 15000);
  }, []);

  return (
    <>
      <HomePopup trigger={buttonPopup} setTrigger={setButtonPopup} />
      <div
        style={{
          margin: 0,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <div className="header-container">
          <h3 className="header-t2">CAUTĂ AUTO DEZMEMBRATE</h3>
          <div className="container-searchbar">
            <input
              className="searchbar"
              type="text"
              placeholder="Ce piesa cauti?"
              value={searchbarValue}
              onChange={(e) => {
                setSearchbarValue(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  gaEventTracker("Searchbar");
                  window.location.href = `https://www.pieseauto.ro/oferte/${searchbarValue}/?utilizator=dezmembrariiasi`;
                }
              }}
            />
            <BiSearchAlt
              style={{
                position: "absolute",
                right: "5%",
                top: 14,
                color: "grey",
              }}
              size={22}
            />
          </div>
          <h1
            style={{
              fontWeight: 400,
              fontSize: 24,
              position: "relative",
              top: 20,
              textAlign: "center",
            }}
          >
            Piese auto second hand{" "}
            <span style={{ color: "var(--pblue)" }}>IEFTINE</span>,{" "}
            <span style={{ color: "var(--pblue)" }}> TESTATE </span>
            și cu <span style={{ color: "var(--pblue)" }}>GARANȚIE </span>
          </h1>
        </div>
        <Servicii />
        <HomeContact />
        <Footer />
      </div>
    </>
  );
}

export default Home;
