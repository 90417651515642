import React from "react";
import "../../styles/Contact.scss";
import Map from "../../components/Map";
import { BsFillTelephoneFill } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { motion } from "framer-motion";

import fata from "../../images/desprenoi/spate.jpg";
import Desprenoi from "./Ofer";

import { Link, useNavigate } from "react-router-dom";
import ContactMesaj from "../../components/ContactMesaj/ContactMesaj";
import { SiGmail } from "react-icons/si";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const HomeContact = () => {
  const gaEventTracker = useAnalyticsEventTracker("Contact");

  const navigate = useNavigate();
  return (
    <>
      <h2 className="header-t1">
        Peste 20 000{" "}
        <a
          onClick={() => gaEventTracker("PA")}
          href="https://www.pieseauto.ro/piese-auto/?utilizator=dezmembrariiasi"
          target="blank"
          id="pieseau"
        >
          piese auto
        </a>{" "}
        și 500{" "}
        <a
          onClick={() => gaEventTracker("PA")}
          href="https://www.pieseauto.ro/dezmembrari-auto/?utilizator=dezmembrariiasi"
          target="blank"
          id="pieseau"
        >
          mașini dezmembrate
        </a>
      </h2>
      <div className="linie-ht1"></div>
      <div className="container-desprenoi">
        <div>
          <p>DESPRE NOI</p>
          <ul>
            <li>
              SC DWS EXPERT OIL este o societate SRL pe piață de 5 ani ce se
              ocupă cu vânzarea produselor provenite din dezmembrarea mașinilor
              rulate.
            </li>

            <li>Achiziționăm și avariate</li>
            <li>
              Oferim vouchere program RABLA / Casare auto <br /> Mai multe
              informații pe pagina{" "}
              <Link
                style={{ color: "var(--pblue)", textDecoration: "none" }}
                to="/programrabla"
              >
                aceasta
              </Link>
            </li>
            <li>Livrăm orice piesă și în țară prin curier rapid</li>
            <li>Oferim prețuri competitive</li>
            <li>
              Se oferă factură și garanție 60 de zile. Verifică politica de{" "}
              <Link
                target="blank"
                to="/termenisiconditii"
                style={{ color: "var(--pblue)", textDecoration: "none" }}
              >
                retur
              </Link>
            </li>
            <li>
              Puteți vedea mai multe informații despre auto dezmembrate{" "}
              <Link
                to="/masini-piese-dezmembrari"
                style={{ color: "var(--pblue)", textDecoration: "none" }}
              >
                aici
              </Link>
            </li>
          </ul>
          <motion.button
            whileHover={{ scale: 1.2 }}
            initial={{ x: 200 }}
            viewport={{ once: true }}
            whileInView={{ x: 0 }}
            transition={{ duration: 0.8 }}
            className="servicii-contacteaza"
            onClick={() => navigate("/contact")}
            style={{ color: "black" }}
          >
            Hai să luăm legătura!
          </motion.button>
        </div>
        <div className="container-desprenoi-imagini">
          <img src={fata} alt="Parcul auto" />
        </div>
      </div>
      <Desprenoi />

      <div id="mapa">
        <Map style={{ border: 0, borderRadius: 0 }} />
      </div>
      <div id="containerContact">
        <div className="informatii">
          Contact:
          <a
            style={{ textDecoration: "none", color: "inherit" }}
            target="blank"
            href="https://goo.gl/maps/xtok72L3MBPXvK1C9"
          >
            <div className="inf">
              <GoLocation
                onClick={() => gaEventTracker("Maps")}
                className="inic"
                style={{ fontSize: "20px", marginRight: "8px" }}
              />
              Dezmembrări auto Daniel - Spiroca
            </div>
          </a>
          <a
            onClick={() => gaEventTracker("Call")}
            href="tel:+40-748-036-393"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="inf">
              <BsFillTelephoneFill className="inic" /> 0748 03 63 93
            </div>
          </a>
          <a
            onClick={() => gaEventTracker("Gmail")}
            style={{ textDecoration: "none", color: "inherit" }}
            target="blank"
            href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=dwsexpertoil@gmail.com&su=Informatii%20piesa&body=Sunt%20interesat%20de%20urmatoarea%20piesa:"
          >
            <div className="inf">
              <SiGmail className="inic" />
              dwsexpertoil@gmail.com
            </div>
          </a>
          <div>
            Program:
            <br />
            <div className="prg">
              <div>
                luni <br />
                marți <br />
                miercuri <br />
                joi <br />
                vineri <br />
                sâmbătă <br />
                duminică <br />
              </div>
              <div>
                09-17 <br />
                09-17 <br />
                09-17 <br />
                09-17 <br />
                09-17 <br />
                09-13 <br />
                închis <br />
              </div>
            </div>
          </div>
        </div>
        <div className="formular-contact">
          <p style={{ color: "white", marginLeft: "3%" }}>
            Întreabă de o piesă / obține o ofertă
          </p>
          <ContactMesaj />
        </div>
      </div>
    </>
  );
};

export default HomeContact;
