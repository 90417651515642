import React from "react";
import useAnalyticsEventTracker from "../hooks/useAnalyticsEventTracker";
import "../styles/multumim.scss";
const Multumim = () => {
  const gaEventTracker = useAnalyticsEventTracker("Contact");

  return (
    <div className="bck-multumim">
      <div className="container-multumim">
        <h1>
          Vă mulțumim pentru timpul dvs și vă vom contacta în cel mai scurt timp
          posibil!
        </h1>
        <p>
          Pentru cel mai rapid răspuns sunați la{" "}
          <span>
            <a
              onClick={() => gaEventTracker("Call")}
              href="tel:+40-748-036-393"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              0748 03 63 93
            </a>
          </span>
        </p>
      </div>
    </div>
  );
};

export default Multumim;
