import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga4";

import { disableReactDevTools } from "@fvilers/disable-react-devtools";

import "./index.scss";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

disableReactDevTools();

ReactGA.initialize("G-Z5FQ4GZZR3");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
  title: window.location.pathname,
});

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
