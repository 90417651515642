import React from "react";
import { BsFillTelephoneFill, BsWhatsapp } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import ContactMesaj from "../components/ContactMesaj/ContactMesaj";
import Footer from "../components/Footer";
import Map from "../components/Map";
import useAnalyticsEventTracker from "../hooks/useAnalyticsEventTracker";
import "../styles/masini2.scss";
const Masini2 = () => {
  const gaEventTracker = useAnalyticsEventTracker("Contact");

  return (
    <>
      <div className="bck-masini2">
        <div className="container-masini2">
          <h1>Dezmembrări auto Iași Daniel - DWS EXPERT OIL</h1>
          <h2>Auto dezmembrări și piese second hand</h2>
          <ul>
            <li>
              <p>
                Avem peste <span> 100 de mașini </span> în prezent în parcul
                nostru auto și peste <span>20,000 de anunțuri publicate.</span>
              </p>
            </li>
            <li>
              <p>
                Vă recomandăm să sunați la{" "}
                <a
                  onClick={() => gaEventTracker("Call")}
                  href="tel:+40-748-036-393"
                  style={{
                    color: "var(--pblue)",
                    border: "none",
                    fontSize: "22px",
                  }}
                >
                  0748 03 63 93
                </a>{" "}
                pentru a afla în mai puțin de <span> 5 minute </span>{" "}
                disponibilitatea piesei pe care o căutați. Sau un mesaj pe
                whatsapp{" "}
                <a
                  target="blank"
                  href={
                    "https://wa.me/40748036393?text=Buna%20ziua,%20Sunt%20interesat%20de%20urmatoarea%20piesa%"
                  }
                  onClick={() => gaEventTracker("Message")}
                  style={{
                    color: "hsl(137, 80%, 40%)",
                    border: "none",
                    fontSize: "22px",
                  }}
                >
                  aici.
                </a>
              </p>
            </li>
            <li>
              <p>
                Oferim un <span>preț competitiv</span> și livrăm în toată țara
                prin <span>curier rapid.</span>
              </p>
            </li>
            <li>
              <p>
                Puteți vedea toate mașinile dezmembrate și piesele disponibile{" "}
                <a
                  style={{
                    color: "var(--pblue)",
                    border: "none",
                    fontSize: "22px",
                  }}
                  target="blank"
                  href="https://www.pieseauto.ro/piese-auto/?utilizator=dezmembrariiasi"
                  onClick={() => gaEventTracker("PA")}
                >
                  aici
                </a>
                .
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="bck-programRabla" style={{ padding: "0 5%" }}>
        <p
          style={{
            fontWeight: 300,
            fontSize: 24,
            position: "relative",
            top: "100px",
          }}
        >
          Contactează-ne
        </p>
        <div className="pr-ia-legatura">
          <div className="pr-fc">
            <p className="pr-fc-t">Formular contact</p>
            <div className="formular-contact">
              <ContactMesaj />
            </div>
          </div>
          <div className="pr-mc">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className="pr-mc-t">Vino la depozit</p>
              <div className="containerContact">
                Ne găsești aici:
                <a
                  onClick={() => gaEventTracker("Maps")}
                  className="inf-a"
                  href="https://goo.gl/maps/xtok72L3MBPXvK1C9"
                >
                  <div className="inf">
                    <GoLocation
                      className="inic"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    />
                    Dezmembrări auto Daniel - Spiroca
                  </div>
                </a>
                <div style={{ marginTop: 20 }}>
                  Program:
                  <br />
                  <div className="prg">
                    <div>
                      luni <br />
                      marți <br />
                      miercuri <br />
                      joi <br />
                      vineri <br />
                      sâmbătă <br />
                      duminică <br />
                    </div>
                    <div>
                      09-17 <br />
                      09-17 <br />
                      09-17 <br />
                      09-17 <br />
                      09-17 <br />
                      09-13 <br />
                      închis <br />
                    </div>
                  </div>
                </div>
              </div>
              <p className="pr-mc-t" style={{ marginBottom: 0 }}>
                Contactează-ne telefonic
              </p>
              <div className="va-container-tel" style={{ padding: "0 5%" }}>
                <div className="va-c va-apel">
                  <a
                    onClick={() => gaEventTracker("Call")}
                    href="tel:+40-748-036-393"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <BsFillTelephoneFill className="va-dt-contact" /> 0748 03 63
                    93
                  </a>
                </div>
                <a
                  onClick={() => gaEventTracker("Message")}
                  style={{ textDecoration: "none" }}
                  target="blank"
                  href={
                    "https://wa.me/40748036393?text=Sunt%20interesat%20de%20urmatoarea%20piesa%"
                  }
                >
                  <div className="va-c va-wh">
                    <BsWhatsapp className="va-dt-contact" />
                    Mesaj Whatsapp
                  </div>
                </a>
              </div>
            </div>
            <div className="pr-mapa">
              <Map style={{ border: 0 }} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Masini2;
