import React from "react";
import useAnalyticsEventTracker from "../hooks/useAnalyticsEventTracker";

const Map = ({ style }) => {
  const gaEventTracker = useAnalyticsEventTracker("Contact");

  return (
    <iframe
      onClick={() => gaEventTracker("Google Maps")}
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2713.579596596207!2d27.64093141585404!3d47.14650182764028!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40caf954c326b2bb%3A0x559b298038af8f76!2sDezmembrari%20auto%20Daniel%20-%20Coca%20Cola%20-Spiroca%20DWS%20EXPERT%20OIL%20IASI!5e0!3m2!1sro!2sro!4v1664968192122!5m2!1sro!2sro"
      width="100%"
      height="100%"
      style={style}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};

export default Map;
