import React from "react";
import "../styles/ProgramRabla.scss";
import masinaR from "../images/ProgramRabla1.jpg";
import Map from "../components/Map";
import ContactMesaj from "../components/ContactMesaj/ContactMesaj";
import { BsFillTelephoneFill, BsWhatsapp } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import Footer from "../components/Footer";
import { SiGmail } from "react-icons/si";
import useAnalyticsEventTracker from "../hooks/useAnalyticsEventTracker";
import { BiDownArrow } from "react-icons/bi";
const ProgramRabla = () => {
  const gaEventTracker = useAnalyticsEventTracker("Contact");

  return (
    <>
      <div className="bck-programRabla">
        <img src={masinaR} />
        <div className="img-cover"></div>
        <h1>Program Rabla Auto IAȘI</h1>
        <BiDownArrow
          style={{
            color: "white",
            position: "absolute",
            top: "180px",
            fontSize: "24px",
          }}
        />
        <div className="container-programRabla">
          <p>
            Societatea <span>DWS EXPERT OIL SRL IASI</span> se ocupă de
            colectarea, tratarea și dezmembrarea vehiculelor scoase din uz
            inclusiv cele destinate reînoirii parcului național
            auto-program Rabla.
          </p>
          <p style={{ marginBottom: "50px" }}>
            Oferim <span>certificat de distrugere</span> pentru vehiculul
            predat, conform legislației în vigoare.
          </p>

          <h2
            style={{
              marginBottom: "20px",
              borderBottom: "1px solid var(--pblue)",
              width: "fit-content",
              color: "var(--pblue)",
            }}
          >
            PROGRAMUL RABLA CLASIC
          </h2>

          <ul style={{ marginBottom: 50 }}>
            <li>
              <p>
                În cadrul acestui program, în schimbul predării unui autovehicul
                cu <span>vechime de peste 6 ani</span>, persoana fizică primește
                un voucher în valoare de <span>6.000 lei</span>, iar la casarea
                celei de-a doua mașini veche, 9.000 lei.
              </p>
            </li>
            <li>
              <p>
                Pe lângă aceste sume, clientul poate beneficia și de următoarele{" "}
                <span>eco-bonusuri</span>:
              </p>

              <ul>
                <li>
                  <h3>
                    PROGRAMUL RABLA CLASIC, pentru vehicule cu motor pe
                    combustie sau hibrid fără Plug-in.
                  </h3>
                  <ul>
                    <li>
                      un eco-bonus de 1500 lei, pentru casarea fiecarui vehicul
                      uzat cu o vechime de cel putin 15 ani de la data
                      fabricatiei si care are norma de poluare Euro 3 sau
                      inferioara
                    </li>
                    <li>
                      un eco-bonus de 1500 lei pentru cumpararea unei masini al
                      carei motor genereaza emisii de CO2 mai mici sau egale cu
                      120 g CO2/km in regim WLTP
                    </li>
                    <li>
                      un eco-bonus de 1500 lei pentru achizitia unei masini noi
                      cu sistem GPL/GNC
                    </li>
                    <li>
                      un eco-bonus de 3000 lei pentru achizitia unui autoturism
                      nou hibrid (data Plug-in)
                    </li>
                  </ul>
                </li>
                <li>
                  <h3>
                    PROGRAMUL RABLA PLUS, pentru vehicule 100% electrice sau
                    hibrid cu tehnologia Plug-in (reincarcabil)
                  </h3>
                  <ul>
                    <li>
                      un eco-tichet de 26.000 de lei pentru achizitia unui
                      autoturism nou hibrid Plug-in cu emisii de CO2 mai mici
                      sau egale cu 80 g CO2/km in regim WLTP
                    </li>
                    <li>
                      un eco-tichet de 29.000 lei, pentru achizitia unui
                      autoturism nou hibrid Plug-in cu emisii de CO2 mai mici
                      sau egale cu 78 CO2/km in regim WTLP
                    </li>
                    <li>
                      un eco-tichet de 51.000 lei pentru achizitia unei masini
                      100 % electrice, dar nu mai mult de 50% din valoarea
                      masinii noi achizitionate
                    </li>
                    <li>
                      un eco-tichet de 54.000 lei pentru achizitia unei masini
                      100% electrice, dar nu mai mult de 50 % din valoarea
                      masinii noi achizitionate
                    </li>
                    <li>
                      În cadrul Rabla Plus se mai poate acorda un eco-bonus de
                      1.500 lei pentru fiecare vehicul uzat care este mai vechi
                      de 15 ani și are norma de poluare Euro 3 sau inferioară.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <h2
            style={{
              marginBottom: "20px",
              borderBottom: "1px solid var(--pblue)",
              width: "fit-content",
              color: "var(--pblue)",
            }}
          >
            Program RABLA LOCAL
          </h2>
          <ul>
            <li>
              <p>
                În cadrul acestui program, in schimbul casării unui autovehicul
                cu <span>vechime de peste 15 ani</span>, persoana fizica
                primeste un voucher în valoare de <span>3.000 lei</span>.
              </p>
            </li>
            <li>
              <p>
                Autovehiculul este considerat eligibil daca indeplineste
                cumulativ urmatoarele conditii:
              </p>
              <ul>
                <li>
                  la data participării în program are o vechime mai mare sau
                  egala cu 15 ani, calculata de la anul fabricatiei;
                </li>
                <li>are norma de poluare Euro 3 sau inferioara;</li>
                <li>
                  conține componentele esentiale (motor, transmisie, caroserie,
                  tren de rulare, sasiu, echipamente electronice de gestionare a
                  autovehiculului si dispozitive catalizator, daca acestea au
                  fost prevazute din fabricatie).
                </li>
              </ul>
            </li>
            <li>
              <p>
                Proprietarul de autovehicul uzat este considerat eligibil dacă
                îndeplinește următoarele condiții, la data solicitarii
                voucherului pentru casare:
              </p>
              <ul>
                <li>
                  este persoana fizica cu domiciliul/resedința in Romania;
                </li>
                <li>
                  detine un autovehicul uzat inmatriculat pe numele sau de cel
                  putin 5 ani si se afla in evidentele fiscale ale UAT-ului
                  solicitant de finantare;
                </li>
                <li>
                  nu este inregistrat cu obligatii restante de plata a taxelor,
                  impozitelor, amenzilor sau contributiilor catre bugetul de
                  stat si bugetul local, conform prevederilor legale in vigoare;
                </li>
                <li>
                  nu a obtinut si nu este pe cale sa obtina finantare prin
                  proiecte sau programe finantate din alte fonduri publice sau
                  fonduri comunitare, inclusiv Programul Rabla Clasic sau
                  Programul Rabla Plus, pentru acelasi autovehicul uzat;
                </li>
                <li>
                  nu este condamnat pentru infractiuni impotriva mediului, prin
                  hotarare judecatoreasca definitiva.
                </li>
              </ul>
            </li>
            <li>
              <p>
                Beneficiarul voucherului de casare se angajeaza ca nu va
                achizitiona un autoturism cu norma de poluare Euro 4 sau
                inferioara in termen de 3 ani de la primirea voucherului.
              </p>
            </li>
            <li>
              <p>
                Este foarte important ca persoana fizica care doreste sa se
                inscrie in program:
              </p>
              <ul>
                <li>
                  sa verifice daca UAT-ul pe raza caruia isi are domiciliul
                  participa in cadrul acestui program;
                </li>
                <li>
                  sa caseze si sa radieze autovehiculul uzat numai dupa ce se
                  asigura ca primaria localitatii pe raza careia isi are
                  domiciliul participa in program.
                </li>
              </ul>
            </li>
          </ul>

          <h3
            style={{
              marginBottom: "20px",
              borderBottom: "1px solid var(--pblue)",
              width: "fit-content",
              color: "var(--pblue)",
            }}
          >
            Casare auto
          </h3>
          <ul style={{ marginBottom: 50 }}>
            <li>
              <p>
                Eliberăm <span>certificat de distrugere / casare auto </span>{" "}
                necesare pentru:
              </p>
            </li>
            <li>
              <p>
                Voucher <span>program rabla auto </span>
              </p>
            </li>
            <li>
              <p>
                <span>Radiere </span> de la primarie pentru plata impozitului
                anual
              </p>
            </li>
            <li>
              <p>
                <span>Radiere</span> din circulatie
              </p>
            </li>
          </ul>
          <h3
            style={{
              marginBottom: "20px",
              borderBottom: "1px solid var(--pblue)",
              width: "fit-content",
              color: "var(--pblue)",
            }}
          >
            Acte necesare pentru casare auto
          </h3>

          <ul style={{ marginBottom: 50 }}>
            <li>
              <p>carte de identitate vehicul uzat (si copie fata verso)</p>
            </li>
            <li>
              <p>
                certificat de inmatriculare vehicul uzat (si copie fata verso)
              </p>
            </li>
            <li>
              <p>carte identitate proprietar auto (si copie)</p>
            </li>
            <li>
              <p>copie CIF (pentru persoana juridica)</p>
            </li>
          </ul>
          <h3
            style={{
              marginBottom: "20px",
              borderBottom: "1px solid var(--pblue)",
              width: "fit-content",
              color: "var(--pblue)",
            }}
          >
            Achizitionam masini
          </h3>

          <ul style={{ marginBottom: 50 }}>
            <li>
              <p>
                Achizitionam masini avariate / neavariate in vederea{" "}
                <span>dezmembrarii </span> lor (nu oferim pret de olx).
              </p>
            </li>
            <li>
              <p>
                Achizitionam masini cu volan pe stanga / dreapta, normale /
                Anglia.
              </p>
            </li>
          </ul>
          <p>
            Licitam pentru <span>parcuri auto</span> sau <span>flote</span>.
          </p>
          {/* <div className="linie-programRabla linie-pr4"></div> */}
          <p>
            Preluam masini de la <span>firme</span>, <span>parcuri auto</span>{" "}
            sau <span>flote </span> in vederea dezmembrarii lor.
          </p>
          <p>Cumparam piese dezmembrari ANGRO.</p>
        </div>

        <p className="pr-contact-telefonic1">
          Pentru mai multe detalii sunati la numarul de telefon{" "}
          <span>0748 03 63 93</span>
        </p>
        <div className="pr-ia-legatura">
          <div className="pr-fc">
            <p className="pr-fc-t">Formular contact</p>
            <div className="formular-contact">
              <ContactMesaj />
            </div>
          </div>
          <div className="pr-mc">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className="pr-mc-t">Vino la depozit</p>
              <div className="containerContact">
                Ne gasesti aici:
                <a
                  className="inf-a"
                  href="https://goo.gl/maps/xtok72L3MBPXvK1C9"
                  onClick={() => gaEventTracker("Maps")}
                >
                  <div className="inf">
                    <GoLocation
                      className="inic"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    />
                    Dezmembrari auto Daniel - Spiroca
                  </div>
                </a>
                <div style={{ marginTop: 20 }}>
                  Program:
                  <br />
                  <div className="prg">
                    <div>
                      luni <br />
                      marti <br />
                      miercuri <br />
                      joi <br />
                      vineri <br />
                      sambata <br />
                      duminica <br />
                    </div>
                    <div>
                      09-17 <br />
                      09-17 <br />
                      09-17 <br />
                      09-17 <br />
                      09-17 <br />
                      09-13 <br />
                      inchis <br />
                    </div>
                  </div>
                </div>
              </div>
              <p className="pr-mc-t" style={{ marginBottom: 0 }}>
                Contacteaza-ne telefonic
              </p>
              <div className="va-container-tel" style={{ padding: "0 5%" }}>
                <div className="va-c va-apel">
                  <a
                    onClick={() => gaEventTracker("Call")}
                    href="tel:+40-748-036-393"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <BsFillTelephoneFill className="va-dt-contact" /> 0748 03 63
                    93
                  </a>
                </div>
                <a
                  onClick={() => gaEventTracker("Message")}
                  style={{ textDecoration: "none" }}
                  target="blank"
                  href={
                    "https://wa.me/40748036393?text=Sunt%20interesat%20de%20programul%20rabla"
                  }
                >
                  <div className="va-c va-wh">
                    <BsWhatsapp className="va-dt-contact" />
                    Mesaj Whatsapp
                  </div>
                </a>
              </div>
            </div>
            <div className="pr-mapa">
              <Map style={{ border: 0 }} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProgramRabla;
