import React from "react";

const Missing = () => {
  return (
    <div
      style={{
        backgroundColor: "var(--white)",
        width: "100%",
        height: "89vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: "0",

        fontSize: "2rem",
      }}
    >
      <h1 style={{ textAlign: "center", fontWeight: "500" }}>404</h1>
      <h2 style={{ textAlign: "center", fontWeight: "500" }}>
        Pagina pe care o căutați nu există
      </h2>
    </div>
  );
};

export default Missing;
