export default function validateInfo(values) {
  let errors = {};
  if (!values.nume.trim()) {
    errors.nume = "Camp obligatoriu";
  }
  if (!values.descriere || values.descriere === "Buna ziua,") {
    errors.descriere = "Camp obligatoriu";
  }
  if (!values.checkbox) {
    errors.checkbox = "Camp obligatoriu";
  }

  return errors;
}
