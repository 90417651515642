import React from "react";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import "./iindex.css";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarLink,
  SidebarMenu,
} from "./SidebarElement";

const Sidebar = ({ isOpen, toggle }) => {
  const gaEventTracker = useAnalyticsEventTracker("Contact");

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="/">Acasa</SidebarLink>
          <SidebarLink to="/masini-piese-dezmembrari">Masini si Piese</SidebarLink>
          {/* <a
            onClick={() => gaEventTracker("Auto")}
            href="https://www.pieseauto.ro/dezmembrari-auto/?utilizator=dezmembrariiasi"
            target="blank"
            style={{
              textDecoration: "none",
              color: "white",
              fontSize: "2rem",
              marginTop: "20px",
              fontFamily: "Work Sans",
            }}
          >
            Masini
          </a>
          <a
            onClick={() => gaEventTracker("Piese Auto")}
            href="https://www.pieseauto.ro/piese-auto/?stare=second&utilizator=dezmembrariiasi"
            target="blank"
            style={{
              textDecoration: "none",
              color: "white",
              fontSize: "2rem",
              marginTop: "20px",
              fontFamily: "Work Sans",
            }}
          >
            Piese
          </a> */}
          <SidebarLink to="/programrabla">Program Rabla</SidebarLink>
          <SidebarLink to="/contact">Contacteaza-ne</SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
