import React from "react";
import { Link } from "react-router-dom";
import "../styles/Footer.scss";
import footerImg from "../images/footer-image.jpg";
import useAnalyticsEventTracker from "../hooks/useAnalyticsEventTracker";

const Footer = () => {
  const gaEventTracker = useAnalyticsEventTracker("Contact");

  return (
    <div className="container-footer">
      <img src={footerImg} className="cf-img" alt="" />
      <div className="ovrl-cf-img"></div>
      <h2
        style={{
          marginTop: 40,
          fontWeight: "500",
          fontSize: 20,
          textAlign: "center",
        }}
      >
        SC DWS EXPERT OIL - DEZMEMBRARI AUTO IASI
      </h2>
      <div className="grid-footer">
        <ul>
          <li>
            <a
              href="https://goo.gl/maps/98zyoqeq8oDMoqra7"
              target="blank"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              ADRESA: Iasi, Str. Sf. Ioan, Nr.17
            </a>
          </li>
          <li>TELEFON: 0748 03 63 93</li>
          <li>EMAIL: dwsexpertoil@gmail.com</li>
        </ul>
        <ul className="footer-p2">
          <li>
            <a
              onClick={() => gaEventTracker("PA")}
              target="blank"
              href="https://www.pieseauto.ro/piese-auto/?utilizator=dezmembrariiasi"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Toate piesele si auto dezmembrate aici |
            </a>{" "}
          </li>
          <li>
            <Link
              to="/programrabla"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Program rabla |
            </Link>{" "}
          </li>
          <li>
            <Link
              to="/contact"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Contact
            </Link>{" "}
            |{" "}
          </li>
          <li>
            <Link
              to="/termenisiconditii"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Termeni si conditii
            </Link>{" "}
            |{" "}
          </li>
          <li>
            <Link
              to="/politica-de-cookies"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Politica de cookies
            </Link>{" "}
            |{" "}
          </li>
          <li>
            <Link
              to="/politica-de-confidentialitate"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Politica de confidentialitate
            </Link>{" "}
            |{" "}
          </li>
          <li>
            <a
              style={{ textDecoration: "none", color: "inherit" }}
              href="https://anpc.ro/"
              target="blank"
            >
              ANPC |
            </a>
          </li>
        </ul>
      </div>
      <p>COPYRIGHTS 2022 SC DWS EXPERT OIL SRL TOATE DREPTURILE REZERVATE</p>
    </div>
  );
};

export default Footer;
