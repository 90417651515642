import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

import "../../styles/Servicii.scss";

const Servicii = () => {
  const navigate = useNavigate();

  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }

  const images = importAll(
    require.context("../../images/serviciiimg", false, /\.(png|jpe?g|svg)$/)
  );

  return (
    <div className="container-servicii">
      <p>NE OCUPĂM CU</p>
      <div className="container-sv">
        <motion.div
          whileHover={{ scale: 1.2 }}
          className="container-sv-img"
          onClick={() => {
            navigate("/masini-piese-dezmembrari");
          }}
        >
          <img src={images["1.png"]} alt="piesa auto" />
          <div className="sline"></div>
          <h3>Piese auto</h3>
          <div className="sline"></div>
        </motion.div>
        <motion.div
          whileHover={{ scale: 1.2 }}
          className="container-sv-img"
          onClick={() => {
            navigate("/masini-piese-dezmembrari");
          }}
        >
          <img src={images["2.png"]} alt="auto" />
          <div className="sline"></div>
          <h3>Auto dezmembrate</h3>
          <div className="sline"></div>
        </motion.div>
        <motion.div
          whileHover={{ scale: 1.2 }}
          className="container-sv-img"
          onClick={() => {
            navigate("/programrabla");
          }}
        >
          <img src={images["3.png"]} alt="semn reciclare" />
          <div className="sline"></div>
          <h3>Program rabla</h3>
          <div className="sline"></div>
        </motion.div>
        <motion.div
          whileHover={{ scale: 1.1 }}
          className="container-sv-img"
          onClick={() => {
            navigate("/contact");
          }}
        >
          <img src={images["4.png"]} alt="indicator locatie" />
          <div className="sline"></div>
          <h3>Contactează-ne</h3>
          <div className="sline"></div>
        </motion.div>
      </div>
      <motion.button
        whileHover={{ scale: 1.2 }}
        className="servicii-contacteaza"
        onClick={() => navigate("/contact")}
      >
        Hai să luam legătura!
      </motion.button>
    </div>
  );
};

export default Servicii;
