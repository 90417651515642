import React from "react";
import "../styles/PaginaInform.scss";

function Politicadecookies() {
  return (
    <>
      <div className="bck-termenisiconditii">
        <div className="termenisiconditii">
          <h2>Politica de cookie-uri</h2>
          <p>
            Un cookie este un fişier text care conţine mici fragmente de
            informaţii trimise navigatorului tău şi memorate în computerul,
            telefonul tău mobil sau alt dispozitiv, atunci când vizitezi un
            site. Acest fişier cookie trimite informaţii înapoi către site ori
            de câte ori îl revizitezi.
          </p>
          <p>
            Cookie-urile pot fi permanente (cunoscute drept cookie-uri
            persistente), care rămân în computerul tău până când le ştergi, sau
            temporare (cunoscute drept cookie-uri pentru sesiune) care sunt
            valabile doar până când închizi fereastra navigatorului tău.
            Cookie-urile pot fi ale părţii-gazdă (first-party), care sunt
            configurate de site-ul pe care îl vizitezi, sau ale unor părţi
            terţe, care sunt configurate de un site diferit de cel pe care îl
            vizitezi.
          </p>
          <p>
            Utilizăm cookie-urile pentru a îmbunătăţi funcţionalitatea
            site-urilor noastre, pentru a te ajuta să navighezi mai eficient de
            la o pagină la alta, pentru a memora preferinţele tale şi, în
            general, pentru a îmbunătăţi experienţa utilizatorilor. Cookie-urile
            pe care le folosim pe site-urile noastre pot fi din următoarele
            categorii:
          </p>
          <p>Cookie-uri strict necesare:</p>
          <p>
            Aceste cookie-uri sunt esenţiale pentru ca tu să poţi naviga pe site
            şi să foloseşti serviciile pe care le-ai solicitat, cum ar fi
            accesarea zonelor sigure ale site-ului.
          </p>
          <p>Cookie-uri pentru performanţă:</p>
          <p>
            Aceste cookie-uri colectează informaţii despre cum utilizează
            vizitatorii un site, de exemplu care sunt paginile cele mai
            vizitate. Aceste cookie-uri nu colectează informaţii care identifică
            vizitatori individuali. Toate informaţiile colectate de aceste
            cookie-uri sunt cumulate şi anonime.
          </p>
          <p>
            Folosim aceste cookie-uri pentru a: <br />
            realiza statistici despre cum sunt utilizate site-urile noastre{" "}
            <br /> măsura impactul campaniilor noastre publicitare.
          </p>
          <p>
            Aceste cookie-uri pot fi permanente sau temporare, ale părţii-gazdă
            sau ale unor părţi terţe. Pe scurt, aceste cookie-uri colectează
            informaţii anonime despre paginile vizitate şi reclamele
            vizualizate.
          </p>
          <p>Cookie-uri pentru funcţionalitate:</p>
          <p>
            Aceste cookie-uri permit unui site să memoreze lucrurile alese de
            tine (cum ar fi numele de utilizator, limba sau ţara în care te
            afli) şi oferă opţiuni îmbunătăţite, mai personale. Aceste
            cookie-uri pot fi de asemenea utilizate pentru a furniza servicii pe
            care le-ai solicitat, cum ar fi vizionarea unui videoclip sau
            comentariul pe un blog. Informaţiile utilizate de aceste cookie-uri
            sunt anonimizate şi ele nu pot înregistra activităţile tale de
            navigare pe alte site-uri.
          </p>
          <p>Folosim aceste cookie-uri pentru a:</p>
          <p>memora dacă ai beneficiat deja de un anumit serviciu</p>
          <p>
            îmbunătăţi experienţa în general pe tot site-ul, memorând
            preferinţele tale.
          </p>
          <p>Cookie-uri pentru publicitate:</p>
          <p>
            Aceste cookie-uri sunt utilizate pentru a limita de câte ori vezi o
            reclamă, precum şi pentru a măsura impactul campaniilor publicitare.
          </p>
          <p>
            Cookie-urile pentru publicitate sunt folosite pentru a gestiona
            publicitatea pe tot site-ul.
          </p>
          <p>
            Cookie-urile pentru publicitate sunt plasate de terţe părţi, cum ar
            fi companiile de publicitate şi agenţii lor, şi pot fi cookie-uri
            permanente sau temporare. Pe scurt, ele sunt legate de serviciile de
            publicitate furnizate pe site-ul nostru de terţe părţi.
          </p>
          <p>Cookie-uri pentru sociale:</p>
          <p>
            {" "}
            aceste cookies sunt folosite de retelele de socializare (Facebook,
            Twitter, Google+, YouTube, Instagram, Pinterest, etc) si permit
            distribuirea continutului de pe https://dezmembrari-pieseauto.ro/ pe
            respectivele retele. Dezmembrari auto SC DWS EXPERT OIL SRL.ro nu
            controleaza aceste cookies, deci, pentru mai multe informatii despre
            cum functioneaza, te rugam sa verifici paginile retelelor de
            socializare.
          </p>
          <p>Cum să administrezi & ştergi cookie-uri</p>
          <p>
            Dacă vrei să impui restricţii, să blochezi sau să ştergi fişiere
            cookie, poţi s-o faci modificând setările navigatorului tău de web.
            Utilizarea https://dezmembrari-pieseauto.ro/ fără respingerea
            cookie-urilor sau a tehnologiilor similare denotă consimţământul
            vizitatorilor pentru utilizarea de către noi a unor astfel de
            tehnologii şi pentru prelucrarea informaţiilor.
          </p>
        </div>
      </div>
    </>
  );
}

export default Politicadecookies;
