import Navbar from "./components/Navbar";
import Home from "./pages/home/Home";
import CookieConsent from "react-cookie-consent";
import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
// import Masini from "./pages/Masini";
import ProgramRabla from "./pages/ProgramRabla";
import Contact from "./pages/Contact";
// import CreeazaMasina from "./pages/CreeazaMasina/CreeazaMasina";
import { useEffect, useState } from "react";
import Sidebar from "./components/sidebar";
// import Anunt from "./pages/anunt/Anunt";
import TermeniSiConditii from "./pages/TermeniSiConditii";
// import Admin from "./pages/Admin/Admin";
// import Login from "./pages/Login/Login";
// import Register from "./pages/Register/Register";
// import RequireAuth from "./components/RequireAuth";
// import PersistLogin from "./components/PersistLogin";

import Missing from "./pages/Missing";
import Layout from "./pages/Layout";

import Multumim from "./pages/Multumim";
import Masini2 from "./pages/Masini2";
import Masini from "./pages/Masini";
import Politicadecookies from "./pages/Politicadecookies";
import PoliticaConf from "./pages/PoliticaConf";

function App() {
  const [toggle, setToggle] = useState(false);

  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <Sidebar
        isOpen={toggle}
        toggle={() => {
          setToggle(!toggle);
        }}
      />
      <Navbar
        toggle={toggle}
        setToggle={() => {
          setToggle(!toggle);
        }}
      />

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route exact path="/" element={<Home />} />
          {/* <Route exact path="auth" element={<Login />} /> */}
          <Route exact path="contact" element={<Contact />} />
          <Route exact path="multumim" element={<Multumim />} />
          {/* <Route exact path="masini" element={<Masini />} /> */}
          {/* <Route exact path="masini/:titlu" element={<Masini />} />
          <Route exact path="masini/:march/:modelh" element={<Masini />} /> */}
          {/* <Route exact path="masini/:marca/:model/:id" element={<Anunt />} /> */}
          <Route
            exact
            path="politica-de-cookies"
            element={<Politicadecookies />}
          />
          <Route
            exact
            path="politica-de-confidentialitate"
            element={<PoliticaConf />}
          />
          <Route exact path="programrabla" element={<ProgramRabla />} />
          <Route exact path="masini-piese-dezmembrari" element={<Masini2 />} />
          {/* <Route
            exact
            path="contact/:url/masini/:marca/:model/:id"
            element={<Contact />}
          /> */}
          <Route
            exact
            path="termenisiconditii"
            element={<TermeniSiConditii />}
          />
          {/* <Route element={<PersistLogin />}>
            <Route element={<RequireAuth allowedRoles={5150} />}>
              <Route exact path="creeazamasina" element={<CreeazaMasina />} />
            </Route>

            <Route element={<RequireAuth allowedRoles={5150} />}>
              <Route
                exact
                path="creeazamasina/:id"
                element={<CreeazaMasina />}
              />
            </Route>
            <Route element={<RequireAuth allowedRoles={5150} />}>
              <Route exact path="admin" element={<Admin />} />
            </Route>
          </Route>

          <Route exact path="register" element={<Register />} /> */}

          <Route path="*" element={<Missing />} />
        </Route>
      </Routes>
      {/* <CookieConsent
        buttonText="Accept"
        buttonStyle={{
          backgroundColor: "var(--pblue)",
          color: "var(--white)",
          width: "100px",
          padding: "10px",
        }}
        declineButtonText="Refuz"
        declineButtonStyle={{
          backgroundColor: "transparent",
          border: "1px solid var(--pblue)",
          width: "100px",
          padding: "10px",
        }}
        enableDeclineButton
      >
        Acest site foloseste cookies pentru a asigura cea mai buna experienta a
        utilizatorului.{" "}
        <Link
          to="/termenisiconditii"
          style={{ textDecoration: "none", color: "var(--pblue)" }}
        >
          Detalii
        </Link>
      </CookieConsent> */}
    </div>
  );
}

export default App;
