import React from "react";
import { BsFillTelephoneFill, BsWhatsapp } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { SiGmail } from "react-icons/si";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import "../../styles/HomePopup.scss";
import { motion } from "framer-motion";

function HomePopup({ trigger, setTrigger }) {
  const gaEventTracker = useAnalyticsEventTracker("Contact");

  return trigger ? (
    <motion.div
      className="homePopup"
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="homePopup-inner">
        <button className="close-btn" onClick={() => setTrigger(false)}>
          x
        </button>
        <h3 style={{ fontWeight: 500 }}>Hai sa luam legatura!</h3>
        <div className="informatii">
          <a
            style={{ textDecoration: "none", color: "inherit" }}
            target="blank"
            href="https://goo.gl/maps/xtok72L3MBPXvK1C9"
            onClick={() => gaEventTracker("Maps")}
          >
            <div className="inf">
              <GoLocation
                className="inic"
                style={{ fontSize: "20px", marginRight: "8px" }}
              />
              Vino la locatie!
              <span className="dezm-auto-daniel-hp" style={{ marginLeft: 4 }}>
                Dezmembrari auto Daniel - Spiroca
              </span>
            </div>
          </a>
          <a
            onClick={() => gaEventTracker("Call")}
            href="tel:+40-748-036-393"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="inf">
              <BsFillTelephoneFill className="inic" /> Apeleaza Acum
            </div>
          </a>
          {/* <a
            onClick={() => gaEventTracker("Gmail")}
            style={{ textDecoration: "none", color: "inherit" }}
            target="blank"
            href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=dwsexpertoil@gmail.com&su=Informatii%20piesa&body=Sunt%20interesat%20de%20urmatoarea%20piesa:"
          >
            <div className="inf">
              <SiGmail className="inic" />
              dwsexpertoil@gmail.com
            </div>
          </a> */}
          <a
            onClick={() => gaEventTracker("Message")}
            style={{ textDecoration: "none", color: "inherit" }}
            target="blank"
            href={
              "https://wa.me/40748036393?text=Sunt%20interesat%20de%20urmatoarea%20piesa%20"
            }
          >
            <div className="inf">
              <BsWhatsapp className="inic" />
              Sau un mesaj pe Whatsapp
            </div>
          </a>
        </div>
        {/* <div className="formular-contact">
          <p style={{ color: "white", marginLeft: "3%" }}>
            Intreaba de o piesa / propune o oferta
          </p>
          <ContactMesaj />
        </div> */}
      </div>
    </motion.div>
  ) : (
    ""
  );
}

export default HomePopup;
