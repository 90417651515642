import React from "react";
import Footer from "../components/Footer";
import "../styles/PaginaInform.scss";
function TermeniSiConditii() {
  return (
    <>
      <div className="bck-termenisiconditii">
        <div className="termenisiconditii">
          <h1>Termeni si conditii</h1>
          <h2>1. Definitii</h2>
          <p>
            SC DWS EXPERT OIL SRL va ofera acces si dreptul de utilizare a
            site-ului https://dezmembrari-pieseauto.ro/ cu toate subdomeniile
            acestuia sub acceptarea acestor termeni si conditii. Prin accesarea,
            utilizarea, obtinerea sau achizitionarea oricarui serviciu pus la
            dispozitia dumneavoastra sunteti de acord sa respectati acesti
            termeni si conditii.
          </p>
          <p>Astfel, următorii termeni vor însemna:</p>
          <p>
            Comandă – un document electronic ce intervine ca formă de comunicare
            între Vânzător și Cumpărător, prin care Vânzătorul este de acord să
            livreze Bunurile și Cumpărătorul este de acord să primească aceste
            Bunuri și să facă plată acestora.
          </p>
          <p>
            Cumpărător – persoană fizică / persoană juridică sau altă entitate
            juridică ce emite o Comandă.
          </p>
          <p>
            Vânzător – Dezmembrari auto SC DWS EXPERT OIL SRL, cu denumirea
            comercială SC DWS EXPERT OIL SRL, având sediul social în Iasi, Iasi,
            Str. Sf. Ioan, Nr. 17, 700381,
            <br /> CUI 17075180, <br />
            Nr. de înregistrare la Registrul Comerțului J22/2902/2004, <br />{" "}
            Numar de telefon: 0743 036 393, <br />
            Email: dwsexpertoil@gmail.
          </p>
          <p>
            Bunuri – orice produs, inclusiv documentele și serviciile menționate
            în Comandă, care urmează a fi furnizate de către Vânzător
            Cumpărătorului.
          </p>
          <p>Contract – o Comandă confirmată de către Vânzător.</p>
          <p>
            Date cu caracter personal inseamna orice informatii privind o
            persoana fizica;
          </p>
          <p>
            Drepturi de Proprietate Intelectuală – toate drepturile imateriale
            cum ar fi know-how, dreptul de autor și drepturi în natură de autor,
            drepturile de bază de date, drepturi de proiectare, drepturi de
            model, patente, mărci înregistrate și înregistrări ale numelor de
            domenii pentru oricare din cele de mai sus.
          </p>
          <p>
            Site – domeniul https://dezmembrari-pieseauto.ro/ și subdomeniile
            acestuia.
          </p>
          <h2>2.Documente Contractuale</h2>
          <p>
            Prin lansarea unei Comenzi electronice pe site-ul
            https://dezmembrari-pieseauto.ro/, Cumpărătorul este de acord cu
            forma de comunicare (e-mail, telefon) prin care Vânzatorul își
            derulează operațiunile. Comanda va fi compusă din următoarele
            documente:
          </p>
          <p>
            Comanda (împreună cu mențiunile clare asupra datelor de livrare și
            facturare) și condițiile sale specifice.
          </p>
          <p>Termeni și condiții</p>
          <p>
            Daca Vânzătorul confirmă Comanda, acest lucru implică o acceptare
            completă a termenilor Comenzii. Acceptarea Comenzii de către
            Vânzător se consideră finalizată atunci când există o confirmare
            electronică (e-mail) din partea Vânzătorului către Cumpărător, fără
            a necesita o confirmare de primire din partea acestuia. Vânzătorul
            nu consideră în nici un moment o comandă neconfirmată ca având
            valoarea unui Contract. Confirmarea Comenzii se face electronic
            (e-mail). Prețurile produselor din comandă sunt valabile 3 zile
            lucrătoare de la data înregistrării comenzii. Termenii și condițiile
            generale de vânzare vor sta la baza Contractului astfel încheiat.
          </p>
          <h2>3.Obligatiile Vanzatorului</h2>

          <p>
            Vânzătorul își va utiliza cunoștințele sale profesionale și tehnice
            pentru a atinge rezultatul stipulat în Comandă și va livra Bunurile
            care îndeplinesc cerințele și specificațiile Cumpărătorului
            exprimate în Comandă;
          </p>
          <p>
            Informațiile prezentate pe site-urile Vânzătorului au caracter
            informativ și pot fi modificate de către Vânzător, fără o anunțare
            prealabilă. Descrierile produselor pot fi incomplete, însă
            vânzătorul face eforturi de a prezenta informațiile cele mai
            relevante, pentru ca produsul să fie utilizat în parametrii pentru
            care a fost achiziționat;
          </p>
          <h2>4.Dreptul de proprietate intelectuală și industrială</h2>
          <p>
            Utilizatorul/Cumpărătorul înțelege dreptul de proprietate
            intelectuală și nu va dezvălui unei terțe părți sau va face publice
            nici una dintre informările primite de la Vânzător.
          </p>
          <p>
            Toate desenele, elementele de grafică și design care apar pe site,
            numele site-ului precum și însemnele grafice sunt mărci înregistrate
            în proprietatea Dezmembrari auto SC DWS EXPERT OIL SRL și nu pot fi
            preluate, copiate sau folosite, fără acordul scris al
            proprietarului.
          </p>
          <p>
            oate elementele de conținut cum sunt descrierile, desenele,
            elementele de grafică și design care apar pe site, incluzând dar
            nelimitându-se la logo-uri, reprezentări stilizate, simboluri
            comerciale, imagini statice, imagini dinamice, text și/sau conținut
            multimedia prezentate pe site, sunt proprietatea exclusivă a
            Dezmembrari auto SC DWS EXPERT OIL SRL, acestuia fiindu-i rezervate
            toate drepturile obținute în acest sens în mod direct sau indirect
            prin licențe de utilizare și/sau publicare.
          </p>
          <p>
            Utilizatorului/Cumpărătorului nu îi este permisă copierea,
            distribuirea, publicarea, transferul către terțe părți, modificarea
            și/sau altfel alterarea, utilizarea, legarea la, expunerea,
            includerea oricărui element conținut de mai sus în orice alt context
            decât cel original intenționat de SC DWS EXPERT OIL SRL includerea
            oricărui element de conținut în afara Site-ului, îndepărtarea
            însemnelor care semnifică dreptul de autor al Dezmembrari auto SC
            DWS EXPERT OIL SRL asupra elementelor de conținut precum și
            participarea la transferul, vânzarea, distribuția unor materiale
            realizate prin reproducerea, modificarea sau afișarea elementelor de
            conținut, decât cu acordul scris expres al Dezmembrari auto SC DWS
            EXPERT OIL SRL.
          </p>
          <h2>5.Drepturile asupra conținutului site-ului</h2>
          <p>
            Întreg continutul site-ului si elementele de grafica, inclusiv dar
            fara a se limita la acestea, respectiv tot continutul in format
            text, precum si sursele tehnice ale tuturor serviciilor si
            facilitatilor prezente si viitoare – cu exceptia cazului cand este
            mentionat expres un alt proprietar – sursele paginilor dar si orice
            alt material, transmis sub orice forma de si catre Utilizatori (prin
            vizualizare directa pe site, prin newslettere etc.) apartin
            Dezmembrari auto SC DWS EXPERT OIL SRL.
          </p>
          <p>
            Continutul site-ului, indiferent de zona in care se afla in site si
            indiferent de tip, poate fi utilizat exclusiv in scopuri personale.
            Orice utilizare de continut de catre terte persoane in alte scopuri
            decat cel personal, se poate face numai cu acordul scris, expres si
            prealabil al Dezmembrari auto SC DWS EXPERT OIL SRL. Prin urmare,
            este interzisa copierea, preluarea, reproducerea, publicarea,
            transmiterea, vanzarea, distributia partiala, integrala sau
            modificata a continutului acestui site sau a oricarei parti a
            acestuia efectuate in alte scopuri decat cel personal, cu
            urmatoarele exceptii:
          </p>
          <p>
            (i) este permisa reproducerea (pe site-uri noncomerciale, forumuri,
            articole din presa etc.) a unor mici fragmente din articolele
            publicate (max. 400 de caractere), fiind obligatorie specificarea
            sursei informatiilor preluate, cu link, sub urmatoarea forma:
            (Sursa: nume site – link catre continutul site-ului).
          </p>
          <p>
            (ii) sunt permise link-urile catre site-ul
            https://dezmembrari-pieseauto.ro/, iar specificarea sursei
            informatiilor se va face dupa fiecare link sau in finalul
            articolului, dupa cum urmeaza: „Informatii furnizate prin
            amabilitatea Dezmembrari auto SC DWS EXPERT OIL SRL – link catre
            continutul site-ului)
          </p>
          <p>
            Utilizatorii se obliga sa respecte toate drepturile de autor si
            toate drepturile conexe acestuia si orice alte drepturi de
            proprietate intelectuala pe care Administratorul site-ului si
            partenerii sai le detin asupra/in legatura cu site-ul
            https://dezmembrari-pieseauto.ro/.
          </p>
          <p>
            Dezmembrari auto SC DWS EXPERT OIL SRL isi rezerva dreptul de a
            actiona in instanta orice persoana si/sau entitate care incalca in
            orice mod prevederile de mai sus. Cererile de utilizare a
            continutului site-ului in orice scop altul decat cel personal pot fi
            facute prin e-mail la adresa mail @ Dezmembrari auto SC DWS EXPERT
            OIL SRL.ro, cu specificatia „In atentia agenției”.
          </p>
          <p>
            Orice persoana care transmite sau publica in orice mod informatii
            ori materiale catre site isi asuma obligatia de a nu prejudicia in
            nici un mod drepturile de autor pe care o terta persoana le-ar putea
            invoca in legatura cu materialele si informatiile transmise in orice
            mod catre site, iar persoanele care expediaza in orice mod
            informatii ori materiale inteleg si accepta ca incalcarea in orice
            mod a acestei obligatii nu poate angaja in niciun mod raspunderea
            Dezmembrari auto SC DWS EXPERT OIL SRL, ci numai raspunderea
            persoanelor respective.
          </p>
          <p>
            Dezmembrari auto SC DWS EXPERT OIL SRL poate derula in orice moment
            campanii publicitare si/sau promotii in orice sectiune din site,
            fara ca aceasta operatiune sa necesite acordul Utilizatorilor
            site-ului. Spatiile si dimensiunea campaniilor publicitare si
            promotiilor nu necesita acordul Utilizatorilor site-ului si pot fi
            schimbate oricand fara a necesita anuntarea prealabila.
          </p>
          <p>
            Dezmembrari auto SC DWS EXPERT OIL SRL nu isi asuma
            responsabilitatea pentru prejudiciile sau pierderile rezultate din
            campaniile publicitare sau promotiile organizate in site, altele
            decat cele organizate strict pe site.
          </p>
          <h2>6.Limitarea răspunderii administratorului site-ului</h2>
          <p>
            Dezmembrari auto SC DWS EXPERT OIL SRL nu isi asuma obligatia si nu
            garanteaza implicit sau expres, pentru continutul site-ului,
            respectiv pentru continutul oferit de partenerii sai sau de
            Utilizatorii site-ului. Cu toate acestea, SC DWS EXPERT OIL SRL va
            depune toate eforturile rezonabile pentru a asigura acuratetea si
            maniera profesionista in care vor fi furnizate informatiile pe site,
            pentru a dobandi si mentine increderea Utilizatorilor in site. In
            acest sens, SC DWS EXPERT OIL SRL va incerca sa corecteze in cel mai
            scurt timp posibil erorile si omisiunile semnalate.
          </p>
          <p>
            Administratorul site-ului nu ofera niciun fel de garantii pentru
            continutul site-ului si in nicio situatie nu poate fi tinut
            raspunzator pentru nicio pierdere sau prejudiciu ce ar putea rezulta
            din utilizarea oricarei parti/secvente/pagini de pe site sau din
            imposibilitatea utilizarii acesteia, indiferent de cauza acesteia
            ori din interpretarea eronata a oricaror prevederi ale continutului
            site-ului.
          </p>
          <p>
            Informatiile furnizate prin intermediul site-ului sunt oferite cu
            buna-credinta, din surse apreciate ca fiind de incredere. In cazul
            in care vreunul din articolele publicate sau orice alta informatie
            intra sub incidenta legii dreptului de autor, rugam Utilizatorii sa
            ne contacteze la adresa de e-mail dwsexpertoil@gmail.com, pentru a
            putea lua masurile ce se impun. Totodată, Utilizatorii trebuie sa
            aibă în vedere faptul ca informatia prezentata poate include
            eventuale informatii inexacte (de ex.: date de ordin tehnic sau
            erori de tastat). Administratorul site-ului va face toate
            diligentele necesare pentru a corecta in cel mai scurt timp posibil
            aceste aspecte.
          </p>
          <p>
            Utilizatorii inteleg si accepta faptul ca SC DWS EXPERT OIL SRLnu
            garanteaza:
          </p>
          <p>ca informatiile continute pe site sunt pe deplin complete;</p>
          <p>
            ca informatiile introduse de Utilizatorii website-ului sunt reale,
            corecte si nu isi asuma raspunderea pentru modul in care vizitatorii
            le folosesc;
          </p>
          <p>
            ca informatiile sau serviciile de pe site vor satisface toate
            cerintele Utilizatorilor, iar pentru folosirea inadecvata a acestora
            Utilizatorii isi asuma intreaga responsabilitate;
          </p>
          <p>
            pentru rezultatele obtinute de Utilizatori ca urmare a folosirii
            informatiilor sau serviciilor disponibile prin intermediul site-ului
            utilizarea informatiilor si a serviciilor facandu-se de catre
            Utilizatori pe propria raspundere;
          </p>
          <p>
            ca serviciile disponibile prin intermediul site-ului vor functiona
            constant, neintrerupt, fara erori – fata de acest aspect,
            Dezmembrari auto SC DWS EXPERT OIL SRL nu isi asuma
            responsabilitatea pentru eventualele pagube pe care Utilizatorii
            le-ar putea avea din cauza nefunctionarii temporare sau defectuoase
            a site-ului sau pentru folosirea informatiilor obtinute prin
            utilizarea link-urilor de pe site catre alte site-uri (utilizarea
            lor este la latitudinea Utilizatorilor).
          </p>
          <p>
            De asemenea, Utilizatorii inteleg si accepta faptul ca Dezmembrari
            auto SC DWS EXPERT OIL SRL nu este responsabila pentru eventuale
            inadvertente, erori sau omisiuni in cadrul informatiilor furnizate
            pe site de catre Utilizatori. Totodata, Utilizatorii inteleg si
            accepta ca Dezmembrari auto SC DWS EXPERT OIL SRL este absolvita de
            orice raspundere pentru mesajele publicitare postate pe site sau
            prin intermediul serviciilor oferite prin site, precum si pentru
            bunurile sau serviciile furnizate de catre autorii acestor mesaje
            publicitare. In mod expres, Utilizatorii site-ului sunt de acord sa
            exonereze de raspundere Dezmembrari auto SC DWS EXPERT OIL SRL
            pentru orice actiune judiciara sau extrajudiciara, care provine ca
            urmare a utilizarii incorecte sau frauduloase a site-ului.
          </p>
          <p>
            Pentru cazuri de forta majora, Dezmembrari auto SC DWS EXPERT OIL
            SRL si/sau operatorii, directorii, angajatii, sucursalele, filialele
            si reprezentantii sai, este exonerata total de orice raspundere.
            Cazurile de forta majora includ, dar nu se limiteaza la, erori de
            functionare ale echipamentului tehnic al SC DWS EXPERT OIL SRL lipsa
            functionarii conexiunii la internet, lipsa functionarii conexiunilor
            de telefon, virusii informatici, accesul neautorizat in sistemele
            Site-ului, erorile de operare, etc.
          </p>
          <p>
            Utilizatorii sunt de acord sa protejeze si sa asigure Dezmembrari
            auto SC DWS EXPERT OIL SRL si/sau pe operatorii, directorii,
            angajatii, sucursalele, filialele si reprezentantii sai de si
            impotriva oricaror cereri, pretentii, actiuni, impuneri, pierderi,
            daune, costuri (incluzand, fara nici un fel de limitare, onorariile
            avocatilor), cheltuieli, judecati, decizii, amenzi, regularizari sau
            alte obligatii rezultate sau relationate cu orice alta actiune a
            Utilizatorilor in legatura cu utilizarea site-ului sau a serviciilor
            oferite prin intermediul acestuia.
          </p>
          <p>
            Dezmembrari auto SC DWS EXPERT OIL SRL nu ofera nicio garantie, nici
            in mod expres si nici implicit, in ceea ce priveste inclusiv, dar
            fara limitare la functionarea site-ului
            https://dezmembrari-pieseauto.ro/, informatia, continutul,
            materialele sau produsele de pe site, precum si potrivirea lor
            pentru un anumit scop. Utilizatorii sunt de acord in mod expres ca
            folosirea acestui site si aplicarea informatiilor se face pe
            propriul lor risc.
          </p>
          <h2>7.Abonarea Utilizatorilor la newslwettere si alerte</h2>
          <p>
            Utilizatorii site-ului au posibilitatea de a primi newslettere si
            alerte prin posta electronica, existand posibilitatea ca
            Utilizatorii sa poata opta oricand pentru varianta de a nu mai primi
            astfel de notificari, cu un singur click pe link-ul de dezabonare
            din newsletter/alerta primita pe mailul introdus explicit la
            abonare.
          </p>

          <h2>8.Facturare și plăți</h2>
          <p>
            Prețul, modalitatea de plată și termenul de plată sunt specificate
            în Comandă. Vânzătorul va emite către Cumpărător o factură pentru
            Bunurile livrate, obligația Cumpărătorului fiind să furnizeze toate
            informațiile necesare emiterii facturii conform cu legislația în
            vigoare.
          </p>
          <p>
            Pentru o corectă comunicare a facturii aferente Comenzii,
            Cumpărătorului îi revin obligațiile de a-și actualiza ori de câte
            ori este cazul datele din Contul său și de a accesa informațiile și
            documentele aferente fiecărei Comenzi existente în Cont.
          </p>
          <p>
            Prin trimiterea Comenzii, Cumpărătorul își exprimă acordul să
            primească facturile și în format electronic prin intermediul poștei
            electronice, la adresa de e-mail menționată în Contul său.
          </p>
          <h2>9.Responsabilități</h2>
          <p>
            Vânzătorulse obligă să expedieze Bunurile și Serviciile în sistem de
            curierat door-to-door către Cumpărător.
          </p>
          <p>
            Vânzătorulse eliberează de riscurile și responsabilitățile asociate
            cu Bunurile și Serviciile în momentul predării acestora către
            societatea de curierat intern cu care Vânzătorul colaborează sau
            către reprezentantul Cumpărătorului.
          </p>
          <p>
            Vânzătorulva asigura ambalarea corespunzătoare a Bunurilor și
            Serviciilor și va asigura transmiterea documentelor însoțitoare.
          </p>
          <p>
            Vânzătorul nu poate fi responsabil pentru daune de orice fel pe care
            Cumpărătorul sau oricare terță parte o poate suferi ca rezultat al
            îndeplinirii de către Vânzător a oricărei din obligațiile sale
            conform Comenzii și pentru daune care rezultă din utilizarea
            Bunurilor după livrare și în special pentru pierderea produselor.
            Vânzătorul va fi răspunzător în cazul în care sub-contractanții
            și/sau partenerii săi de orice fel implicați în executarea Comenzii
            nu îndeplinesc oricare din obligațiile contractuale.
          </p>
          <p>
            Vânzătorul nu își asumă responsabilitatea pentru descrierile
            produselor prezentate pe site. Imaginile sunt prezentate pe site cu
            titlu de exemplu, iar produsele livrate pot diferi de imaginile și
            descrierile expuse pe site în orice mod, datorită modificării
            caracteristicilor și design-ului fără notificare prealabilă.
            Vânzătorul își rezervă dreptul să completeze și să modifice orice
            informație de pe site fără notificare prealabilă.
          </p>
          <p>
            Vânzătorul nu garantează disponibilitatea în stoc a produselor
            afișate, motiv pentru care va avea dreptul de a nu livra parțial sau
            integral o anumită comandă în cazul în care anumite produse nu mai
            apar în oferta curentă sau nu sunt disponibile.
          </p>
          <p>
            În cazul în care prețurile sau alte detalii referitoare la produse
            au fost afișate greșit, inclusiv din cauza faptului că au fost
            introduse greșit în baza de date, Vânzătorulîși alocă dreptul de a
            anula livrarea respectivului produs și de a anunța clientul în cel
            mai scurt timp despre eroarea aparută, dacă livrarea nu s-a efectuat
            încă.
          </p>
          <p>
            Vânzătorulnu răspunde pentru prejudiciile create ca urmare a
            nefuncționării site-ului precum și pentru cele rezultând din
            imposibilitatea accesării anumitor link-uri publicate pe site.
          </p>
          <p>
            Valoareamaximă a obligațiilor Vânzătorului față de orice client în
            cazul nelivrării sau livrării necorespunzătoare este valoarea
            sumelor încasate de Vânzător de la clientul respectiv.
          </p>
          <p>
            Produsele comercializate pe site sunt destinate utilizării
            personale, iar revânzarea acestora este strict interzisă, conform
            Codului Fiscal.
          </p>
          <h2>10.Livrarea produselor</h2>
          <p>Livrarea nu este gratuită și se face prin curier rapid.</p>
          <p>
            Livrare din Iasi, jud. Iasi prin: <br />
            Ridicare de la sediu <br /> Curier, cost estimativ livrare: 22 de
            lei <br />
            Timp necesar pentru procesarea comenzii: 1 zi. <br />
            Metode de plată: Ramburs, Direct la vânzător <br />
            Tarife standard pentru expedierea coletelor: <br />
            Tarife valabile pentru volume normale pana in 5 kg în reţeaua de
            acoperire a curierului. Contra cost pentru volum, greutate mai mare
            sau distanta in afara localitatii aprox 1leu/kg / km
          </p>
          <p>
            Dacă pachetul nu poate fi livrat (destinatarul nu răspunde, adresa
            specificată de dumneavoastră este greșită etc), veți fi contactat
            telefonic de către curier. Dacă nu se poate lua contact cu
            destinatarul, coletele rămân la curierul zonal 7 zile, după care
            revin la Expeditor.
          </p>
          <p>
            Nu suntem răspunzători pentru expedieri întârziate, pierderea,
            distrugerea, deteriorarea, ne livrarea sau livrarea greșită a unei
            expedieri sau a unei părți din aceasta în cazul în care sunt
            generate de următoarele situații/circumstanțe independente de voința
            noastră DAR vom incerca sa facem clientul fericit
          </p>
          <h2>11.Acceptarea</h2>
          <p>
            Acceptarea va fi facută atunci când Bunurile sunt conforme cu
            caracteristicile tehnice menționate în Comandă. În cazul în care
            Cumpărătorul descoperă că Produsele livrate nu sunt conforme
            specificațiilor tehnice, atunci Vânzătorul va aduce la conformitate
            Produsele. De asemenea, pentru produsele vândute și livrate de
            Dezmembrari auto SC DWS EXPERT OIL SRL, Cumpărătorul beneficiază de
            returul produselor în 14 zile conform sectiunii "Returnarea
            produselor".
          </p>
          <h2>12.Transferul proprietății</h2>
          <p>
            Proprietatea asupra Bunurilor va fi transferată la livrare, după
            efectuarea plății din partea Cumpărătorului în locația indicată în
            comandă (întelegând prin livrare – semnarea de primire a
            documentului de transport furnizat de curier sau semnarea de primire
            pe factura fiscală în cazul livrărilor efectuate de personalul
            Vânzătorului). În cazul livrării prin curier, acesta nu este
            autorizat de către Vânzător să permită Cumpărătorului deschiderea
            coletelor înainte de semnarea de livrare, ci doar ulterior semnării
            de livrare și achitării eventualei contravalori a acestora.
          </p>
          <h2>13.Returnarea produselor</h2>
          <p>Piesa se poate returna în primele 14 zile de la primirea ei.</p>
          <p>Cumpărătorul va suporta costul transportului de retur.</p>
          <p>
            Condiții de retur conform legislației în vigoare: Potrivit
            articolului 9 alin. 1 din Ordonanța Guvernului nr. 34 din 12 iunie
            2014 privind drepturile consumatorilor în cadrul contractelor
            încheiate cu profesioniștii, aveți dreptul de a vă retrage din
            contractele încheiate la distanță, fără a preciza motivele.
          </p>
          <p>
            Perioada de retragere expiră după 14 zile de la data la care intrați
            dumneavoastră sau o parte terță, alta decât transportatorul,
            indicată de dumneavoastră, în posesia fizică a produselor / a
            ultimului produs.
          </p>
          <p>
            Pentru a vă exercita dreptul de retragere trebuie să ne informați cu
            privire la decizia dumneavoastră de a vă retrage din prezentul
            contract, utilizând o declarație neechivocă transmisă prin e-mail.
            În acest scop puteți folosi modelul de retragere de aici; folosirea
            lui nu este însă obligatorie.
          </p>
          <p>
            Pentru a respecta termenul-limită de retragere este suficient să
            trimiteți comunicarea privind exercitarea dreptului de retragere
            înainte de expirarea perioadei de retragere.
          </p>
          <p>
            Dacă vă retrageți, vom rambursa orice sumă pe care am primit-o de la
            dumneavoastră, inclusiv costurile livrării, cu excepția costurilor
            suplimentare determinate de faptul că ați ales altă modalitate de
            livrare decât cel mai ieftin tip de livrare standard oferit de noi,
            fără întârzieri nejustificate și, în orice caz, nu mai târziu de 14
            zile de la data la care suntem informați cu privire la decizia
            dumneavoastră de a vă retrage din prezentul contract. Vom efectua
            această rambursare folosind aceeași modalitate de plată ca și cea
            folosită pentru tranzacția inițială, cu excepția cazului în care
            v-ați exprimat acordul expres pentru o altă modalitate de
            rambursare; în orice caz, nu vi se vor percepe comisioane ca urmare
            a unei astfel de rambursări.
          </p>
          <p>
            Putem amâna rambursarea până la data la care primim înapoi produsele
            sau până la momentul la care ne-ați furnizat dovada că ați trimis
            înapoi produsele, fiind valabilă data cea mai apropiată.
          </p>
          <p>
            Expediați produsele sau înmânați-le direct nouă, fără întârzieri
            nejustificate și, în orice caz, în decurs de maximum 14 zile de la
            data la care ne-ați comunicat retragerea. Termenul este respectat
            dacă produsele sunt trimise înapoi înainte de expirarea perioadei de
            14 zile.
          </p>
          <p>Va trebui să suportați costul direct al returnării produselor.</p>
          <p>
            Sunteți responsabil doar pentru diminuarea valorii produselor care
            rezultă din manipulări, altele decât cele necesare pentru
            determinarea naturii, calităților și funcționării produselor.
          </p>
          <p>Produse comercializate de SC DWS EXPERT OIL SRL.</p>
          <p>
            Operatorii noștri vă vor oferi suport în alegerea modalității de
            returnare și vă vor oferi detaliile necesare cu privire la adresa de
            expediere a produselor, ambalare sau contactarea firmei de curierat.
          </p>
          <p>
            Informaţii adiţionale pentru politica de retur: Se accepta retur in
            14 zile lucratoare de la primirea coletului doar in conditiile in
            care piesa nu prezinta rupturi, uzura, deteriorari, etc
          </p>

          <h2>14.Legea aplicabilă</h2>
          <p>
            Prezentul contract este supus legii Române. Eventualele litigii
            apărute între Dezmembrari auto SC DWS EXPERT OIL SRL și
            utilizatori/clienți/cumpărători se vor rezolva pe cale amiabilă sau,
            în cazul în care aceasta nu va fi posibilă, litigiile vor fi
            soluționate de instanțele judecătorești Române competente.
          </p>
          <h2>15. Modificarea termenilor și condițiilor</h2>
          <p>
            Dezmembrari auto SC DWS EXPERT OIL SRL are dreptul de a modifica
            oricând și în orice mod oricare dintre prevederile cuprinse în
            Termeni și condiții sau Termenii și Condițiile în întregime, fără
            nicio notificare prealabila și fără a fi obligata sa îndeplinească
            vreo alta formalitate fata de Utilizatori. Orice modificare este
            acceptata deplin și necondiționat de către Utilizatorii site-ului
            prin simpla utilizare sau accesare a site-ului sau a oricărei
            facilitați oferite de site, intervenite oricând după operarea
            modificării, iar neacceptarea oricărei modificări atrage obligația
            respectivului Utilizator de a înceta de îndată accesarea site-ului
            și/sau utilizarea în orice mod a serviciilor oferite prin
            intermediul acestuia.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default TermeniSiConditii;
