import React from "react";

function PoliticaConf() {
  return (
    <>
      <div className="bck-termenisiconditii">
        <div className="termenisiconditii">
          <h2>Prelucrarea datelor cu caracter personal</h2>
          <p>
            Pentru a asigura respectarea dreptului utilizatorilor site-ului la
            protecția datelor cu caracter personal, am implementat măsuri
            specifice de protecție, luând în considerare legislația română,
            precum și cerințele stabilite de Regulamentul (UE) 2017/679 –
            aplicabil în întreaga Uniune Europeană începând cu data de 25 mai
            2018 („Regulamentul”).
          </p>
          <p>
            Datele cu caracter personal reprezintă orice informație prin care
            dvs. puteți fi identificat, în special printr-un element de
            identificare cum ar fi un nume, un număr de identificare, date de
            localizare, un identificator online sau unul sau mai multe elemente
            aflate în legătură cu identitatea dvs. fizică, fiziologică,
            genetică, psihică, economică, culturală sau socială.
          </p>
          <p>
            Societatea noastră ia toate măsurile necesare pentru a asigura
            respectarea dreptului dvs. la protecția datelor cu caracter
            personal, iar prezentele condiţii reprezintă notificareastabilită de
            art. 13 sau 14 din Regulament prin care explicăm de ce colectăm
            datele dvs. cu caracter personal, modul în care protejăm aceste
            date, precum și care sunt drepturile dvs. în legătură cu această
            colectare de date.
          </p>
          <p>
            Vă încurajăm să citiți cu atenție documentul și să ne solicitați
            orice informație suplimentară sau orice lămurire pe care o
            considerați necesară, cu privire la conținutul acestei informări.
          </p>
          <h3>
            1. Considerații generale privind datele dumneavoastră personale
          </h3>
          <p>
            In conformitate cu Regulamentul (UE) 2017/679, Dezmembrari auto SC
            DWS EXPERT OIL SRL va administra in conditii de siguranta si numai
            pentru scopurile specificate, datele personale furnizate de
            Utilizatori. Prin Termeni si Conditii Utilizatorii sunt informati ca
            datele personale pe care le furnizeaza urmeaza a fi prelucrate in
            scopul oferirii in conditii optime de catre SC DWS EXPERT OIL SRLa
            serviciilor pe internet, a serviciilor de furnizare bunuri si
            servicii, a serviciilor de reclama, marketing si publicitate si a
            serviciilor de statistica.
          </p>
          <p>
            Dezmembrari auto SC DWS EXPERT OIL SRL realizează următoarele
            operaţiuni de prelucrare: colectarea, inregistrarea, organizarea,
            stocarea, adaptarea, modificarea, extragerea, consultarea,
            utilizarea si, in unele cazuri, transmiterea catre terti, in baza
            exclusiva si reglementata a unui contract comercial care asigura
            securitatea si confidentialitatea datelor, precum si respectarea
            drepturilor utilizatorului, a datelor personale ale Utilizatorilor
            site-ului pentru a administra, mentine, imbunatati si obtine
            informaţii cu privire la serviciile pe care le ofera, precum si
            pentru a preveni erori si scurgeri de informatii prin reteaua IT
            proprie, incalcari ale legii sau termenilor contractuali.
          </p>
          <p>
            Datele personale ce urmeaza a fi colectate vor putea fi folosite,
            inclusiv prin crearea automată de profiluri (pentru acei Utilizatori
            care şi-au exprimat consimţământul explicit) si pentru a personaliza
            intr-un grad cat mai mare serviciile oferite Utilizatorilor prin
            intermediul site-ului precum si in scopuri de marketing. Crearea
            automată de profiluri nu va viza datele minorilor, datele personale
            ale acestora nefiind prelucrate în acest scop.
          </p>
          <p>
            Datele personale vor fi furnizate de catre Utilizatori, la optiunea
            lor proprie, in momentul crearii unui cont valabil pe site sau
            abonarii la un serviciu disponibil pe site, pentru a beneficia de
            serviciile si produsele oferite prin intermediul acestuia. La
            inregistrarea in site sunt solicitate Utilizatorului anumite date
            personale, precum numele si prenumele, sexul, data nasterii, adresa
            de e-mail, telefon, profesie, obisnuinte/preferinte/comportament,
            dar si alte informatii cu caracter personal.
          </p>
          <p>
            Utilizatorul este singurul raspunzator de toate datele furnizate la
            momentul crearii contului de utilizator pe site. Pentru confirmarea
            datelor si contului, Utilizatorul va fi anuntat la adresa de e-mail
            declarata la crearea contului. Acest e-mail de confirmare are rolul
            stoparii actiunilor frauduloase ale unor utilizatori care folosesc
            adrese de e-mail ale altor persoane pentru a crea conturi fictive.
            Daca primiti un asemenea mesaj, in conditiile in care nu v-ati
            inregistrat personal pe site va rugam sa ne trimiteti un e-mail la
            adresa dwsexpertoil@gmail.com pentru a sterge contul respectiv in
            maximum 3 zile lucratoare. E-mailul va conţine, în format pdf şi
            versiunea în vigoare a prezentului Contract.
          </p>
          <p>
            Utilizatorul nu este obligat sa furnizeze aceste date, ele fiind
            necesare i) evidentei despre folosirea site-ului si ii) al oferirii
            in conditii optime a serviciilor prin intermediul acestuia, pentru
            aducerea la cunostinta a campaniilor promotionale, in scop de
            reclama, pentru actiuni de marketing si publicitate personalizate si
            pentru accesul Utilizatorului la facilitati suplimentare. Refuzul
            Utilizatorului de a furniza datele solicitate determina
            neparticiparea la actiunile promotionale organizate prin intermediul
            site-ului si nefolosirea facilitatilor, serviciilor suplimentare
            oferite exclusiv Utilizatorilor cu un cont in acest website.
          </p>
          <p>
            Website-ul va putea fi utilizat şi dacă Utilizatorul decide să nu
            creeze un profil prin furnizarea datelor personale, cu excepţiile
            stabilite în cadrul Politicii de Cookies (parte integrantă a
            prezentului Contract)
          </p>
          <p>
            Conform Regulamentul (UE) 2017/679 si Legii nr. 677/2001,
            Utilizatorii beneficiaza de dreptul de acces, de interventie asupra
            datelor, dreptul de a nu fi supus unei decizii individuale si
            dreptul de a se adresa justitiei. Totodata, Utilizatorii au dreptul
            sa se opuna prelucrarii datelor personale care ii privesc si sa
            solicite stergerea datelor.
          </p>
          <p>
            Pentru exercitarea acestor drepturi, Utilizatorii pot face o cerere
            in scris in acest sens, pe care o vor trimite prin e-mail la adresa
            dwsexpertoil@gmail.com cu specificația „Solicitare date cu caracter
            personal”.
          </p>
          <p>
            Site-ul se angajează sa nu trimită mesaje spam (mesaje comerciale
            pentru care nu are acceptul prealabil explicit al Utilizatorului) si
            sa întreprindă toate mijloacele tehnice accesibile pentru a asigura
            securitatea si confidențialitatea datelor utilizatorului.
          </p>
          <p>
            Site-ul își rezerva dreptul de a desființa, fără aviz prealabil,
            conturile si accesul membrilor care încalcă Termenii și condițiile,
            care întreprind activități dovedite de frauda, calomnie sau atac la
            securitatea și confidențialitatea informațiilor din cadrul site-ului
            sau a companiei operatoare a site-ului.
          </p>
          <h2>
            2. Datele având caracter personal pe care le colectăm, temeiurile
            colectării și scopurile colectării
          </h2>
          <p>
            În cadrul raportului de comunicare online stabilit intre site și
            utilizator, colectăm și prelucrăm datele dvs. având caracter
            personal, colectarea și prelucrarea acestor date fiind necesară
            furnizării de conținut informativ si promoțional către utilizator,
            în urma completării, de către acesta, a datelor cu caracter personal
            în mod voluntar, în cadrul site-ului, prin accesarea uneia sau mai
            multora dintre secțiunile: creare cont, actualizare cont, abonare la
            newsletter, completare chestionar online, completare formular
            online, pentru obținerea de informații personalizate sau beneficii
            comerciale, în cadrul campaniilor promovate pe site.
          </p>
          <p>
            Temeiurile colectării de date cu caracter personal pe site pot fi
            următoarele: persoana vizata și-a dat consimțământul pentru
            prelucrarea datelor cu caracter personal pentru unul sau mai multe
            scopuri specifice prelucrarea este necesara pentru executarea unui
            contract la care persoana vizata este parte sau pentru a face
            demersuri la cererea persoanei vizate înainte de încheierea unui
            contract. prelucrarea este necesara în vederea îndeplinirii unei
            obligații legale care ii revine operatorului. prelucrarea este
            necesara pentru a proteja interesele vitale ale persoanei vizate sau
            ale altei persoane fizice prelucrarea este necesara pentru
            îndeplinirea unei sarcini care servește unui interes public sau care
            rezulta în exercitarea autorității publice cu care este investit
            operatorul. prelucrarea este necesara în scopul intereselor legitime
            urmărite de operator sau de o parte terța, cu excepția cazului în
            care prevalează interesele sau drepturile și libertățile
            fundamentale ale persoanei vizate, care necesita protejarea datelor
            cu caracter personal, în special atunci când persoana vizata este un
            copil.
          </p>
          <p>
            În enumerările de mai jos vă prezentăm în mod detaliat ce date cu
            caracter personal colectate precum și temeiul, scopul și durata
            colectării acestora. Furnizarea datelor indicate mai jos nu este
            obligatorie pentru a vizualiza Website-ul, cu excepția celor care
            vizează Cookies.
          </p>
          <p>
            Datele indicate sunt necesare numai pentru a presta servicii
            specifice pentru Utilizatorii care le transmit.
          </p>
          <p>Date având caracter personal pe care le colectăm: </p>
          <p>Nume, prenume, adresa de e-mail, număr de telefon</p>
          <p>
            Temeiurile colectării acestor date: 1), 6) Scopurile colectării
            datelor având caracter personal: Pentru furnizarea accesului la
            conținut și răspunsuri la întrebări și solicitări transmise de către
            utilizator online, pentru transmiterea de comunicări, oferte și
            beneficii constând în acces la servicii și produse Modalitatea si
            perioada de colectare a datelor: In cadrul bazei de date securizate,
            pana la exprimarea dorinței explicite de ștergere de către
            utilizator sau pana la 10 ani de la ultima activitate a
            utilizatorului pe site. După cei 10 ani, datele vor fi anonimitate
            electronic.
          </p>
          <p>
            Cookies, marcaje de timp (data și ora accesării), istoricul
            navigării pe site
          </p>
          <p>
            Temeiurile colectării acestor date: 1), 6) Scopurile colectării
            datelor având caracter personal: Pentru monitorizarea traficului
            site-ului și a istoricului accesărilor acestuia, pentru realizarea
            ierarhiei de conținut și identificarea conținutului cel mai relevant
            pentru utilizator. Modalitatea si perioada de colectare a datelor:
            În cadrul bazei de date securizate, pana la exprimarea dorinței
            explicite de ștergere de către utilizator sau pana la 10 ani de la
            ultima activitate a utilizatorului pe site. După cei 10 ani, datele
            vor fi anonimitate electronic.
          </p>
          <h2>
            3. Modul de păstrare a datelor dvs. având caracter personal,
            locație, perioadă de păstrare
          </h2>
          <p>
            Vom stoca datele dvs. cu caracter personal pentru o perioadă care nu
            depășește perioada necesară îndeplinirii scopurilor în care sunt
            prelucrate datele, iar, în cazurile în care avem o obligația legală
            de a păstra datele dvs. cu caracter personal o anumită perioadă de
            timp, perioada de păstrare va fi cea prevăzută de lege. În funcție
            de situația concretă, această perioadă va varia, putând fi cuprinsă
            între 1 zi și 10 ani. Pentru o bună înțelegere a modului de
            păstrare, a locației în care datele dvs. cu caracter personal vor fi
            păstrate și a perioadei exacte pentru care aceste date vor fi
            păstrate în evidențele sau în sistemele noastre, vă rugăm să
            consultați tabelul de mai sus. În scopul garantării dreptului
            utilizatorului de a fi protejat în ceea ce privește prelucrarea
            datelor cu caracter personal, implementam, pentru anumite categorii
            de date având un caracter sensibil sau care sunt apte să afecteze
            drepturile într-o manieră semnificativă, măsuri speciale de natură
            tehnică și organizatorică, care să protejeze aceste categorii de
            date cu caracter personal.
          </p>
          <h2>
            4. Drepturile utilizatorilor cu privire la datele cu caracter
            personal și exercitarea acestora
          </h2>
          <p>
            În vederea protejării la un standard cât mai ridicat a datelor
            utilizatorul are serie de drepturi reglementate de lege, pe care le
            prezentăm pe scurt în cele ce urmează, rugându-vă ca pentru orice
            detalii suplimentare să nu ezitați să contactați responsabilul cu
            protecția datelor cu caracter personal din cadrul Societății
            noastre, utilizând detaliile de contact următoare:
            dwsexpertoil@gmail.com, Iasi, Iasi, Str. Sf. Ioan, Nr. 17, 700381,
            telefon 0743036393. Dreptul de acces Utilizatorul are dreptul de a
            obține accesul la datele sale cu caracter personal pe care le
            prelucrăm, precum și dreptul de a obține copii ale acestora. La
            solicitarea utilizatorului, prima astfel de copie vă va fi oferită
            de către noi în mod gratuit, urmând ca eventualele copii
            suplimentare pe care le veți solicita să poată fi supuse unei taxe
            corespunzătoare efortului necesar de extragere si formatare pentru
            transmitere. Copia datelor având caracter personal vă poate fi
            oferită fie în format electronic, fie în format fizic, în funcție de
            solicitarea dvs. şi de natura datei solicitate. Pentru solicitarea
            informațiilor despre datele cu caracter personal existente în site,
            precum si pentru a solicita ștergerea parțiala sau totala a acestor
            date, este nevoie sa fie adresata cererea folosind adresa de e-mail
            dwsexpertoil@gmail.com sau sa fie expediata o scrisoare prin posta
            sau curierat la sediul social al SC DWS EXPERT OIL SRL De asemenea,
            utilizatorul are dreptul să obțina orice informații suplimentare
            relevante (cum ar fi motivul prelucrării datelor cu caracter
            personal, categoriile de date cu caracter personal pe care le
            colectăm, informații privind prelucrarea și divulgarea acestor date
            și orice alte asemenea informații). Dreptul la rectificarea datelor
            Utilizatorul are dreptul de a obține rectificarea oricăror
            inexactități ce vizează datele sale cu caracter personal prelucrate
            de către noi. De asemenea, are dreptul de a obține completarea
            oricăror date cu caracter personal care sunt incomplete. Orice
            utilizator este incurajat sa contacteze site-ul la adresa
            dwsexpertoil@gmail.com ori de câte ori observa că există o
            inexactitate în privința datelor sale cu caracter personal sau că
            datele sale cu caracter personal prelucrate de către Dezmembrari
            auto SC DWS EXPERT OIL SRL sunt incomplete. Dreptul la ștergerea
            datelor Utilizatorul are dreptul la ștergerea datelor având caracter
            personal. Acest drept nu este un drept absolut, ceea ce înseamnă că
            legea instituie anumite limitări în privința exercitării acestui
            drept („dreptul de a fi uitat„). Dreptul la restricționarea
            prelucrării datelor Utilizatorul are dreptul de a obține
            restricționarea prelucrării datelor sale cu caracter personal pe
            care le colectăm și prelucrăm, în special în cazul în care contesta
            exactitatea datelor, în cazul în care prelucrarea datelor este
            ilegală ori în cazul în care prelucrarea acestor date nu mai este
            necesară, potrivit legii. Dreptul de a obiecta Utilizatorul are
            dreptul de a obiecta la prelucrarea datelor având caracter personal
            de către noi, în special în cazul în care prelucrarea se realizează
            în scopuri de marketing sau din motive legate de situația
            particulară în care se afla acesta, caz in care datele acestuia
            trebuie anonimitate în cel mai scurt timp de la sesizarea obiectării
            din baza de date a site-ului și anonimizarea acestora trebuie
            confirmata utilizatorului. Dreptul la retragerea consimțământului
            Pentru datele cu caracter personal prelucrate pe baza
            consimțământului, utilizatorul are dreptul de a isi retrage
            consimțământul in orice moment, la fel de ușor cum l-a și acordat
            inițial. Retragerea consimțământului nu va afecta însă legalitatea
            prelucrării datelor pe care am realizat-o înainte de retragerea
            consimțământului. Dreptul la retragerea consimțământului nu este
            unul absolut, ceea ce înseamnă că există cazuri în care datele nu
            vor fi șterse ca urmare a retragerii consimțământului (de exemplu în
            cazul în care datele având caracter personal sunt folosite pentru ca
            site-ul să se conformeze unei obligații legale). Aplicarea
            retragerii consimțământului se aplica din momentul înregistrării
            acesteia si operarea retragerii consimțământului se va realiza in
            maximum 3 zile lucrătoare de la înregistrare. Dreptul de a depune o
            plângere în fața autorității competente Utilizatorul are dreptul de
            a depune o plângere la Autoritatea Națională de Supraveghere a
            Prelucrării Datelor cu Caracter Personal cu referire la aspectele ce
            privesc prelucrarea datelor cu caracter personal de către Societatea
            noastră. Procesul decizional automatizat, inclusiv crearea de
            profiluri și dreptul de a solicita ca deciziile bazate pe
            prelucrarea automată a datelor sau care afectează într-o măsură
            semnificativă să fie luate de persoane fizice, nu exclusiv de
            computere Datele cu caracter personal sunt colectate și prelucrate
            printr-un proces decizional automatizat în vederea personalizării
            informațiilor şi comunicărilor comerciale adresare dumneavoastră.
            Procesul decizional automatizat ce implică datele de sănătate are la
            bază consimțământul dumneavoastră expres. În cadrul acestor procese
            datele dumneavoastră sunt protejate prin măsuri de securizare
            specială de tipul criptării datelor și securității avansate la nivel
            de server al bazei de date. Aveți dreptul de a solicita modificarea
            modului în care sunt prelucrate automat datele dumneavoastră
            personale, solicitând verificarea procesului automatizat prin
            intervenție umană. În acest scop vă rugăm să ne contactați la adresa
            de email dwsexpertoil@gmail.com.
          </p>
          <h2>5. Exercitarea drepturilor</h2>
          <p>
            Pentru ca utilizatorul sa își poată exercita drepturile precizate
            mai sus, pentru a ne adresa orice întrebare referitoare la aceste
            drepturi sau pentru a ne cere lămuriri cu privire la oricare dintre
            prevederile acestei informări, vă rugăm să ne contactați oricând,
            utilizând informațiile de contact. Cum se pot transmite cererile sau
            plângerile – persoana de contact Pentru a afla mai multe informații
            cu privire la datele cu caracter personal, cu privire la modul în
            care datele sunt colectate, prelucrate și protejate sau pentru a
            cere orice lămuriri referitoare la cele menționate în acești Temeri
            și Condiții orice utilizator poate contacta la orice moment
            responsabilul cu protecția datelor cu caracter personal din cadrul
            companiei, adresându-se prin mail la dwsexpertoil@gmail.com sau prin
            corespondenta postala la Iasi, Iasi, Str. Sf. Ioan, Nr. 17, 700381.
            16 Forță majoră Nici una din părți nu va fi răspunzătoare pentru
            neexecutarea obligațiilor sale contractuale, dacă o astfel de
            neexecutare este datorată unui eveniment de forță majoră. Forța
            majoră este evenimentul imprevizibil, în afara controlului părților
            și care nu poate fi evitat.
          </p>
        </div>
      </div>
    </>
  );
}

export default PoliticaConf;
