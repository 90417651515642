import React from "react";
import { FiPhoneCall } from "react-icons/fi";
import { MdLocationOn } from "react-icons/md";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import { TbLocation } from "react-icons/tb";
import useAnalyticsEventTracker from "../hooks/useAnalyticsEventTracker";
import { motion } from "framer-motion";

import "../styles/Navbar.scss";

function Navbar({ toggle, setToggle }) {
  const gaEventTracker = useAnalyticsEventTracker("Contact");
  return (
    <>
      <motion.div
        className="navbar1-container"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <ul>
          <li>
            <a
              href="tel:+40-748-036-393"
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={() => gaEventTracker("Call")}
            >
              <section>
                <FiPhoneCall style={{ marginRight: 5 }} />{" "}
                <span>Apeleaza Acum</span>
              </section>
            </a>
          </li>
          <li>
            <a
              onClick={() => gaEventTracker("Maps")}
              target="blank"
              href="https://goo.gl/maps/98zyoqeq8oDMoqra7"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <section style={{ cursor: "pointer" }}>
                <MdLocationOn style={{ marginRight: 5 }} />
                <span>
                  <h1
                    style={{
                      margin: "inherit",
                      fontWeight: "inherit",
                      fontSize: "inherit",
                    }}
                  >
                    Dezmembrari auto Daniel - Dws Expert Oil
                  </h1>
                </span>
              </section>
            </a>
          </li>
          <li>
            <a
              onClick={() => gaEventTracker("IO")}
              style={{ textDecoration: "none", color: "inherit" }}
              target="blank"
              href="https://www.google.com/maps/dir//Dezmembrari+auto+Daniel+-+Coca+Cola+-Spiroca+DWS+EXPERT+OIL+IASI+Strada+Sf%C3%A2ntul+Ioan+Ia%C8%99i+700381/@47.1464982,27.6431201,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x40caf954c326b2bb:0x559b298038af8f76!2m2!1d27.6431201!2d47.1464982"
            >
              <section>
                <TbLocation style={{ marginRight: 5 }} />
                <span>Indicatii de orientare</span>
              </section>
            </a>
          </li>
        </ul>
      </motion.div>
      <div className="navbar2-container">
        <ul>
          <li>
            <Link to="/" className="link">
              <h1 style={{ margin: 0 }}>Dezmembrari auto Iasi</h1>
            </Link>
          </li>
          <li className="lid">
            <Link to="/masini-piese-dezmembrari" className="link">
              <p style={{ marginLeft: 0 }}>Masini si piese</p>
            </Link>
            <Link to="/programrabla" className="link">
              <p>Program Rabla</p>
            </Link>
          </li>
          <li className="lid">
            <Link to="/contact" className="link">
              <p style={{ margin: 0, marginRight: 20 }}>Contact</p>
            </Link>
            <Link to="/contact" className="link">
              <p style={{ margin: 0 }}>Obtine o Oferta</p>
            </Link>
          </li>
          <li className="hamburgermenu" onClick={() => setToggle(!toggle)}>
            <GiHamburgerMenu />
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navbar;
