import React from "react";
import "../../styles/Ofer.scss";

import handS from "../../images/oferim/handshake.jpg";
import garant from "../../images/oferim/guarantee.png";
import deliver from "../../images/oferim/deliver1.jpg";

const Desprenoi = () => {
  return (
    <div className="ofer">
      <div className="containerOf">
        <div className="subtext" id="first"></div>
        <div className="st" id="s1">
          <h6>Seriozitate</h6>
          <p>
            Transparență totală, te anunțăm de eventualele defecte ale piesei și
            comunicăm eficient.
          </p>
        </div>
        <img className="trio" src={handS} alt="Doua persoane dand mana" />
      </div>
      <div className="containerOf">
        <div className="subtext" id="second"></div>
        <div className="st" id="s2">
          <h6>Garanție</h6>
          <p>
            Piesele nefuncționale pot fi returnate în decurs de 60 de zile de la
            achiziție.
          </p>
        </div>
        <img
          className="trio"
          src={garant}
          alt="persoana in costum in timp ce semneaza documente"
        />
      </div>
      <div className="containerOf">
        <div className="subtext" id="third"></div>
        <div className="st" id="s3">
          <h6>Livrare</h6>
          <p>Livrăm în toată țara prin curier rapid.</p>
        </div>
        <img className="trio" src={deliver} alt="curier cu un colet in mana" />
      </div>
    </div>
  );
};

export default Desprenoi;
