import React from "react";
import { SiGmail } from "react-icons/si";
import { BsFillTelephoneFill, BsWhatsapp } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import { useLocation } from "react-router-dom";
import ContactMesaj from "../components/ContactMesaj/ContactMesaj";
import Footer from "../components/Footer";
import Map from "../components/Map";
import "../styles/Contact.scss";
import "../styles/Anunt.scss";

import useAnalyticsEventTracker from "../hooks/useAnalyticsEventTracker";
const Contact = () => {
  const location = useLocation();
  const gaEventTracker = useAnalyticsEventTracker("Contact");

  return (
    <>
      <div className="bck-programRabla">
        <h2 style={{ fontWeight: 500, position: "absolute" }}>
          Contactează-ne
        </h2>
        <div className="pr-ia-legatura">
          <div className="pr-fc">
            <p className="pr-fc-t">Formular contact</p>
            <div className="formular-contact">
              <ContactMesaj
                url={
                  location.pathname !== "/contact"
                    ? location.pathname
                    : undefined
                }
              />
            </div>
          </div>
          <div className="pr-mc">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className="pr-mc-t">Vino la depozit</p>
              <div className="containerContact">
                Ne găsești aici:
                <a
                  onClick={() => gaEventTracker("Maps")}
                  className="inf-a"
                  href="https://goo.gl/maps/xtok72L3MBPXvK1C9"
                >
                  <div className="inf">
                    <GoLocation
                      className="inic"
                      style={{ fontSize: "16px", marginRight: "5px" }}
                    />
                    Dezmembrări auto Daniel - Spiroca
                  </div>
                </a>
                <div style={{ marginTop: 20 }}>
                  Program:
                  <br />
                  <div className="prg">
                    <div>
                      luni <br />
                      marți <br />
                      miercuri <br />
                      joi <br />
                      vineri <br />
                      sâmbătă <br />
                      duminică <br />
                    </div>
                    <div>
                      09-17 <br />
                      09-17 <br />
                      09-17 <br />
                      09-17 <br />
                      09-17 <br />
                      09-13 <br />
                      închis <br />
                    </div>
                  </div>
                </div>
              </div>
              <p className="pr-mc-t" style={{ marginBottom: 0 }}>
                Contactează-ne telefonic
              </p>
              <div className="va-container-tel" style={{ padding: "0 5%" }}>
                <div className="va-c va-apel">
                  <a
                    onClick={() => gaEventTracker("Call")}
                    href="tel:+40-748-036-393"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <BsFillTelephoneFill className="va-dt-contact" /> 0748 03 63
                    93
                  </a>
                </div>
                <a
                  onClick={() => gaEventTracker("Message")}
                  style={{ textDecoration: "none" }}
                  target="blank"
                  href={
                    "https://wa.me/40748036393?text=Sunt%20interesat%20de%20urmatoarea%20piesa%"
                  }
                >
                  <div className="va-c va-wh">
                    <BsWhatsapp className="va-dt-contact" />
                    Mesaj Whatsapp
                  </div>
                </a>
              </div>
            </div>
            <div className="pr-mapa">
              <Map style={{ border: 0 }} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
