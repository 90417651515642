import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";
import emailjs from "@emailjs/browser";
import "../../styles/Contact.scss";
import validateInfo from "./ValidateInfo";
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

function ContactMesaj() {
  const gaEventTracker = useAnalyticsEventTracker("Contact");

  const [contact, setContact] = useState({
    nume: "",
    email: "",
    telefon: "",
    descriere: "Buna ziua,",
  });
  const [errors, setErrors] = useState({});
  const [checkbox, setCheckbox] = useState();
  const navigate = useNavigate();

  const createMesaj = () => {
    emailjs
      .send(
        "contact_service_form",
        "contact_form29",
        contact,
        "j-uAUT8IyQlZRAwWF"
      )
      .then(
        function (response) {
          console.log("Success!", response.status, response.text);
          navigate("/multumim");
        },
        function (error) {
          console.log("Failed...", error);
        }
      );
  };

  const handleChangeValue = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const err = validateInfo({ ...contact, checkbox: checkbox });
    setErrors(err);
    if (Object.keys(err).length === 0) {
      gaEventTracker("Formular_Contact");
      createMesaj();
    } else console.log(err);
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="fc-np">
          <div style={{ width: "100%" }}>
            <input
              placeholder="Nume"
              type="text"
              value={contact.nume}
              name="nume"
              onChange={handleChangeValue}
            />
            <p style={{ textAlign: "center", selfAlign: "center" }}>
              {errors.nume && errors.nume}
            </p>
          </div>
        </div>
        <div className="fc-np" style={{ marginBottom: "15px" }}>
          <div>
            <input
              placeholder="Email"
              type="email"
              value={contact.email}
              name="email"
              onChange={handleChangeValue}
            />
          </div>
          <div>
            <input
              placeholder="Telefon"
              type="int"
              value={contact.telefon}
              name="telefon"
              onChange={handleChangeValue}
            />
          </div>
        </div>

        <div className="fc-nps">
          <textarea
            placeholder="Cu ce va putem ajuta?"
            type="text"
            id="fc-mesaj"
            name="descriere"
            value={contact.descriere}
            onChange={handleChangeValue}
          />

          <p>{errors.descriere && errors.descriere}</p>
        </div>
        <p style={{ color: "white" }}>
          {!checkbox ? (
            <ImCheckboxUnchecked
              className="checkbox-cm"
              onClick={() => {
                setCheckbox(true);
              }}
            />
          ) : (
            <ImCheckboxChecked
              className="checkbox-cm"
              onClick={() => {
                setCheckbox(false);
              }}
            />
          )}
          Am citit si sunt de acord cu{" "}
          <Link
            to="/termenisiconditii"
            style={{ textDecoration: "none", color: "orange" }}
          >
            Termenii si Conditiile
          </Link>
        </p>
        <p>{errors.checkbox && errors.checkbox}</p>
        <input type="submit" id="homepage-fc-submit" value="Trimite" />
      </form>
    </div>
  );
}

export default ContactMesaj;
